@use '@/components/colors';
@use '@/components/icons';

.react-datepicker-popper {
    z-index: 1000;
}

.react-datepicker {
    font-size: 1rem;
    color: colors.$black;
    border-radius: 0;
    border: 1px solid colors.$grey1;
}

select.monthPicker {
    width: 12rem;
}

.react-datepicker__time-container,
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
    width: 7rem;
}

.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
    right: 7rem;
}

.react-datepicker__input-container input {
    width: 12rem;
    background-image: icons.$calendar;
    background-size: 1.5rem;
    background-position: right 0.5rem center;
    background-repeat: no-repeat;

    &::-ms-clear {
        display: none;
    }

    &:active,
    &:focus {
        background-image: icons.$calendarHighlight;
    }
}

$previousArrow: icons.swap-color(icons.$linkArrowLeft, '#FFFFFF', colors.$activeBlue);
$previousArrowHover: icons.swap-color(icons.$linkArrowLeft, '#FFFFFF', colors.$brandBlue);

.react-datepicker__navigation-icon::before,
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
    border-style: none;
}

.react-datepicker__navigation--previous {
    background: $previousArrow;
    background-repeat: no-repeat;
    background-size: 1rem;
    width: 1rem;
    height: 1rem;
    border: none;
    margin-top: 0.5rem;
    margin-left: 0.25rem;

    &:hover,
    &:active,
    &:focus {
        background: $previousArrowHover;
    }
}

$nextArrow: icons.swap-color(icons.$linkArrowRight, '#FFFFFF', colors.$activeBlue);
$nextArrowHover: icons.swap-color(icons.$linkArrowRight, '#FFFFFF', colors.$brandBlue);

.react-datepicker__navigation--next {
    background: $nextArrow;
    background-repeat: no-repeat;
    background-size: 1rem;
    width: 1rem;
    height: 1rem;
    border: none;
    margin-top: 0.5rem;
    margin-right: 0.25rem;

    &:hover,
    &:active,
    &:focus {
        background: $nextArrowHover;
    }
}

.react-datepicker__header {
    background-color: colors.$white;
    padding: 0.5rem;
    border-bottom: none;
}

.react-datepicker-popper[data-placement^='bottom'] {
    .react-datepicker__triangle {
        border-bottom-color: colors.$grey0;
    }

    .react-datepicker__triangle:before {
        border-bottom-color: colors.$grey1;
    }
}

.react-datepicker__day-names {
    margin-top: 0.5rem;
    margin-bottom: 0;
    padding-top: 0.5rem;
    border-top: 1px solid colors.$grey1;
    border-bottom: 0;
}

.react-datepicker__day-name {
    margin: 0;
    width: 2.5rem;
    font-weight: 600;
}

.react-datepicker__day {
    background-color: transparent;
    color: colors.$activeBlue;
    border: 1px solid colors.$grey1;
    margin: 0;
    padding-top: 0.1rem;
    padding-bottom: 0.1rem;
    width: 2.5rem;
    border-radius: 0;

    &.react-datepicker__day--today {
        text-decoration: underline;
        font-weight: normal;
    }

    &.react-datepicker__day--outside-month {
        visibility: hidden;
    }

    &:hover,
    &:focus,
    &:active {
        background-color: colors.$brandBlue;
        color: colors.$white;
        border-radius: 0;
    }
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
    background-color: colors.$activeBlue;
    color: colors.$white;
}

.react-datepicker__day--selected {
    background-color: colors.$activeBlue;
    color: colors.$white;
}

.react-datepicker__day--disabled,
.react-datepicker__month-text--disabled,
.react-datepicker__quarter-text--disabled,
.react-datepicker__day--disabled:hover,
.react-datepicker__month-text--disabled:hover,
.react-datepicker__quarter-text--disabled:hover,
.react-datepicker__day--disabled:active,
.react-datepicker__month-text--disabled:active,
.react-datepicker__quarter-text--disabled:active,
.react-datepicker__day--disabled:focus,
.react-datepicker__month-text--disabled:focus,
.react-datepicker__quarter-text--disabled:focus {
    color: colors.$grey2;
    background-color: colors.$grey0;
}
