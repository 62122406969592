@use '@/components/colors';
@use '@/components/icons';

.modal-backdrop {
    background-color: colors.$black;

    &.show {
        opacity: 0.5;
    }
}

.modal {
    .modal-lg {
        max-width: 80%;
        margin-left: auto;
        margin-right: auto;

        @media (max-width: 767px) {
            max-width: 90%;
        }
    }

    .modal-dialog {
        .modal-title {
            color: colors.$white;
            font-size: 1.5rem;
        }

        .modal-header {
            background-color: #666;
            color: colors.$white;
            padding: 0.5rem 1rem;
            border: 0;

            .close {
                background-image: icons.$iconClose;
                stroke: colors.$activeBlue;
                background-repeat: no-repeat;
                background-size: 1.5rem;
                background-position-y: 0.5rem;
                margin: -3rem 0 0 auto;
                width: 1.2rem;
                height: 1.2rem;

                &:hover,
                &:active,
                &:focus {
                    background-image: icons.$iconCloseHover;
                    stroke: colors.$brandBlue;
                    outline: none;
                }

                [aria-hidden='true'] {
                    display: none;
                }
            }
        }

        .modal-content {
            border: 0;
            background-color: transparent;
            border-radius: 0;
        }

        .modal-body,
        .modal-footer {
            background-color: colors.$white;
            border: 0;
        }

        .modal-body {
            .list-group-item:first-child {
                border-radius: 0;
            }

            .list-group-item:last-child {
                border-radius: 0;
            }

            .list-group {
                margin-left: -1rem;
                margin-right: -1rem;
            }

            .container {
                margin-bottom: 1rem;
            }
        }
    }
}
