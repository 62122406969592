@use '@/components/colors';

input[type='checkbox'],
input[type='radio'] {
    border-color: colors.$grey4;

    &:checked {
        border-color: colors.$brandBlue;
    }

    &:hover {
        border-color: colors.$activeBlue;
    }
}
